<style>
      .three-steps-panel > * {
        padding-top: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 0px !important;
      }
    </style>

<template>
  <main id="about">
    <!-- Hero Banner -->
    <v-img id="hero-banner" position="top right" :src="require('../assets/img/banner-sky.jpg')" height="640" light></v-img>

    <!-- Our Story -->
    <v-sheet id="story" color="grey lighten-3">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="py-8" style="max-width: 1280px">
        <v-row justify="center">
          <v-col cols="12" md="8" data-aos="fade-right">
            <v-card class="rounded-lg" elevation="4">
              <v-card-text>
                <h5 class="text-h5 blue--text text--darken-3 mb-3">
                  Our Story
                </h5>
                <div class="text-body-1 mb-4">
                  My One Rule started with a smile and a desire to find the best way to talk to nearly everyone you meet. 
                  After a decades-long journey that took our founder around the world, to the halls of Congress, to the rooms of renowned think-tanks, 
                  to sitting across the table from some of the most recognized leaders in the United States, and after talking with hundreds of people with various backgrounds, 
                  he found that where there was respect for one another, conversations thrived. Seems simple, but simple is sometimes lost. 
                  So he decided to join forces with a few other visionaries and artists to encourage the timeless principle: treat others the way you want to be treated.
                </div>
                <div class="text-body-1 mb-4">
                  Treat others the way you want to be treated. It transcends religion, appearance, opinion, geography, and anything else that makes us different. 
                  It is the mother of all other values that affect human interaction such as kindness, civility, honesty, loyalty, and more.
                </div>
                <div class="text-body-1">
                  When you practice this principle, it is the end of murder, stealing, cheating, corruption, abuse, bullying, and other destructive behaviors. 
                  If you seek a firm footing for people to achieve better solutions, start with this timeless principle. Together, we can have a better today and safer tomorrow.
                </div>
              </v-card-text>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-img :src="require('../assets/img/about/our-mission.jpg')" :aspect-ratio="21/9">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
          <v-col class="d-flex" cols="12" md="4" data-aos="fade-left">
            <v-card class="d-flex flex-column flex-grow-1 rounded-lg" elevation="4">
              <v-card-text>
                <h5 class="text-h5 blue--text text--darken-3 mb-3">
                  Our Mission
                </h5>
                <p class="text-body-1 mb-0">
                  The My One Rule mission is to inspire a movement where individuals and institutions encourage and celebrate the practice of the 
                  timeless principle “treat others the way you want to be treated.”
                </p>
              </v-card-text>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-img :src="require('../assets/img/about/our-story.jpg')">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Three Steps -->
    <v-sheet id="steps">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="pb-8" style="max-width: 1280px">
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-h4 font-weight-light my-4">
              The Three Steps
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-card class="rounded-lg" style="overflow: hidden" elevation="4">
              <v-expansion-panels :value="threeSteps" accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header color="grey lighten-3">
                    1. Live My One Rule
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="three-steps-panel">
                    <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                    <v-img :aspect-ratio="16/9" width="100%">
                      <iframe src="https://www.youtube.com/embed/eB8obC9wyuM?rel=0" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                    </v-img>
                    <v-row justify="center" no-gutters>
                      <v-col class="pa-4" cols="12">
                        <div class="text-body-1 text-left mb-4">
                          To live something, you have to make it your own. It has to be personal. At first, living My One Rule may be a conscious choice. 
                          No, I won’t respond in anger if someone cuts me off on the road. No, I won’t seek revenge if someone at school hurts my feelings. 
                          No, I won’t gossip about that annoying co-worker who doesn’t have a clue. 
                          No, I won’t nag my partner if I see s/he is in a bad mood and treating me unfairly. 
                          Over time, when we choose over and over again to treat others with respect, 
                          living My One Rule will become a habit … something we do more naturally.
                        </div>
                        <div class="text-body-1 text-left mb-4">
                          As we develop self-awareness in our lives, identify our emotional kryptonite, 
                          and stay curious about our feelings – we can develop solid personal values that guide our decision making. 
                          You will never go wrong with being slow to react to anger and quick to forgive.
                        </div>
                        <div class="text-body-1 text-left">
                          The result will change your life. Better relationships. Improved moods. Clearer thinking. Better control over your thoughts and feelings. 
                          Better decision making. More effective communication. More success. More peace. We have found MOR is easier to live with reminders, 
                          they help us when we start to get upset and nudge us to do good for others.
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header color="grey lighten-3">
                    2. Teach My One Rule
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="three-steps-panel">
                    <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                    <v-img :aspect-ratio="16/9" width="100%">
                      <iframe src="https://www.youtube-nocookie.com/embed/D5NMcAmvlHc?rel=0" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                    </v-img>
                    <v-row justify="center" no-gutters>
                      <v-col class="pa-4" cols="12">
                        <div class="text-body-1 text-left mb-4">
                          Teaching My One Rule can take place individually and in collective spaces. 
                          At an individual level, you can teach others about My One Rule by example and by having reminders that will spark conversations and questions 
                          (e.g., someone sees your coffee mug “My One Rule” and asks you about it).
                        </div>
                        <div class="text-body-1 text-left mb-4">
                          At a group level (e.g. schools, workplace, public institutions, places of worship, etc), 
                          you can teach others about My One Rule by incorporating our reminders into your tailored program. 
                          My One Rule is broad enough that any institution can tailor it to its specific needs and personality.
                        </div>
                        <div class="text-body-1 text-left">
                          My One Rule already has designed effective tools for you to use on Day One of its implementation (e.g., hats, posters, clothing, reminder cards, etc). 
                          Because the concept of My One Rule is simple and intuitively understood, it can be incorporated into any teaching program. 
                          Building a culture and program on such an easy and universally known and accepted concept is a natural fit for any organization. 
                          The conversation starters, like My One Rule on a shirt or cap cause people to ask, what is your one rule? This provides a great opportunity to share.
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header color="grey lighten-3">
                    3. Make It The Standard
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="three-steps-panel">
                    <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                    <v-img :aspect-ratio="16/9" width="100%">
                      <iframe src="https://www.youtube-nocookie.com/embed/eg1Uj8K9qII?rel=0" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                    </v-img>
                    <v-row justify="center" no-gutters>
                      <v-col class="pa-4" cols="12">
                        <div class="text-body-1 text-left">
                          My One Rule should be the standard, not the exception. 
                          Treating others with respect always is not only the standard of kindness and maturity but of professionalism as well. 
                          A consistent expectation of My One Rule in any space will improve life experience in that space.
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Our Logo -->
    <v-sheet id="logo" color="grey lighten-3">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="pb-8" style="max-width: 1280px">
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-h4 font-weight-light my-4">
              Our Logo
            </div>
          </v-col>
        </v-row>
        <v-row class="mb-4" justify="center">
          <v-col class="d-flex" cols="12" md="6" data-aos="fade-up">
            <v-img :src="require('../assets/img/logo/love-god-logo-lg.png')" height="100%">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="10" data-aos="fade-up">
            <div class="text-body-1">
              Our Circle Logo is the result of a collaborative effort between artists and visionaries committed to being the change needed in the world. 
              The logo features our motto - Treat Others The Way You Want to be Treated - in gold text wrapped around the circle’s circumference: the words shine through the darkness. 
              The logo shows our shared planet wrapped in the caring hands of the peoples of the world. The hands gently touch each other, molding our home into a heart shape. 
              At the center of the earth is a red heart symbolizing love, compassion, and life. The universe and her stars are in the background, 
              showing there is no ceiling to humanity’s reach if we could only live by My One Rule. At the bottom of the logo, there is a hand pointing up, 
              which is the symbol for My One Rule.
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </main>
</template>

<script>
export default {
  name: "About",
  data: () => ({
    threeSteps: 0
  }),
  mounted () {
    if (this.$route.query.target) {
      this.threeSteps = parseInt(this.$route.query.step);

      var target = this.$route.query.target;
      var options = { duration: 0 }
      this.$vuetify.goTo(target, options);
    }
  }
}
</script>